import Head from "next/head";

type SEOProps = {
  title?: string;
  titleSuffix?: string;
  description?: string;
  ogImageUrl?: string;
};

const SEO = ({ title, titleSuffix, description, ogImageUrl }: SEOProps) => {
  const pageTitle = title ? `${title} | ${titleSuffix || "Fueled"}` : undefined;
  const imageUrl = ogImageUrl ? ogImageUrl : "https://fueled.com/assets/images/logo.png";
  return (
    <Head>
      {/* Site tags */}
      {pageTitle && <title>{pageTitle}</title>}
      {description && <meta name="description" content={description} />}

      {/* OG tags */}
      {pageTitle && <meta property="og:title" content={pageTitle} />}
      {description && <meta property="og:description" content={description} />}
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {/* Twitter tags */}
      <meta name="twitter:card" content="summary_large_image" />
      {pageTitle && <meta name="twitter:title" content={pageTitle} />}
      {description && <meta name="twitter:description" content={description} />}
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Head>
  );
};

export default SEO;
