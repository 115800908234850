"use strict";

import { projectType } from "components/ProjectCard";

// A mapping of project type tags
// Used on: /home, /projects/____
export const projectTypeMap = {
  "apple-watch": {
    text: "watchOS",
  },
  android: {
    text: "Android",
  },
  blockchain: {
    text: "Blockchain",
  },
  brand: {
    text: "Branding",
  },
  design: {
    text: "Design",
  },
  "digital-transformation": {
    text: "Digital Transformation",
  },
  ios: {
    text: "iOS",
  },
  web: {
    text: "Web",
  },
};

type colorType = {
  primary: string;
  accent?: string;
  gradient?: string;
  customGradientOverride?: string;
};

type displayOptions = {
  logoMaxWidthPercent?: number;
  arrowOverride?: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
  overlay?: {
    color: string;
    opacity: number;
  };
};

// Company Info
type companyType = {
  name: string;
  shortName?: string;
  links?: { appStore?: string; playStore?: string; website?: string };
  project?: projectType;
  color?: colorType;
  display?: displayOptions;
};

// 0-9
const NINEgag: companyType = {
  name: "9GAG",
  links: {
    appStore: "https://itunes.apple.com/us/app/9gag-best-lol-pics-gifs/id545551605?mt=8",
  },
  project: {
    types: ["ios", "digital-transformation"],
    description: {
      intro: "Most Popular Meme Site",
      copy: [
        "Averaging 250 million visits per month across their respective platforms and an in-app engagement time of over 11 minutes, 9GAG is an international hub for user-generated content.",
        "9GAG hired Fueled to redesign and build the company’s iOS and Android apps, leveraging Fueled’s product-driven approach to define features which would engage their audience of young trendsetters (75% are younger than 25yo) and optimize for content virality.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/9gag/screens/iphone-x/home.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/9gag/screens/iphone-x/feed.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: {
    primary: "#1E1E1E",
  },
};

// A
const afterlight: companyType = {
  name: "Afterlight",
  links: {
    playStore: "https://play.google.com/store/apps/details?id=com.fueled.afterlight&hl=en",
  },
  project: { types: ["android"] },
};

const aap: companyType = {
  name: "Advance Auto Parts",
  project: { types: ["ios", "design"] },
  links: {
    appStore: "https://apps.apple.com/us/app/advance-auto-parts/id1486902421?ls=1",
    playStore: "https://play.google.com/store/apps/details?id=com.advanceauto.mobile.commerce.dist",
    website: "https://shop.advanceautoparts.com",
  },
};

const apartner = {
  name: "Apartner",
  color: {
    primary: "#161616",
  },
};

const apple: companyType = {
  name: "Apple",
  project: {
    types: ["web"],
    description: {
      intro: "An App For The App People",
      copy: [
        "Searching for ways to show off Apple hardware and software features to a global team of third-party sales teams, Apple tasked us with developing a gamified education platform to make Apple products become an aspirational purchase to sales teams that traditionally sold Android devices.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/apple/screens/iphone-x/home.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/apple/screens/iphone-x/quiz.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: { primary: "#000000" },
};

const artsy: companyType = {
  name: "Artsy",
  links: {
    website: "https://artsy.net",
  },
};

const artwired = {
  name: "Artwired",
  color: {
    primary: "#221424",
  },
};

// B
const barneysNewYork: companyType = {
  name: "Barneys New York",
  shortName: "Barneys NYC",
  project: {
    types: ["web"],
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/barneys-new-york/screens/macbook-air/coat.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
        {
          src: "/images/projects/barneys-new-york/screens/macbook-air/screen-menu.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
      ],
      layout: "web",
    },
  },
  color: {
    primary: "#000000",
  },
};

const blueBottleCoffee: companyType = {
  name: "Blue Bottle Coffee",
  links: {
    website: "https://bluebottlecoffee.com",
  },
};

const buildspec: companyType = {
  name: "Buildspec",
};

// C
const californiaDmv: companyType = {
  name: "California DMV",
  project: {
    types: ["web", "design"],
    description: {
      intro: "California’s Interactive DMV Website",
      copy: [
        "Fueled's CMS team spearheaded a comprehensive digital overhaul of the California DMV, significantly enhancing usability for its 72 million yearly visitors, improving accessibility, and ensuring seamless compliance with the Federal REAL ID Act. Launching successfully to millions in its inaugural week, the revamped site received an accolade for utilizing innovative IT to bolster services and citizen experiences, particularly during the pandemic.",
      ],
    },
    deviceSet: {
      screens: [
        {
          src: "/images/projects/california-dmv/screens/macbook-air/california-dmv-screen-tile.jpg",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
        {
          src: "/images/projects/california-dmv/screens/macbook-air/california-dmv-screen-hero.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
      ],
      overlapDevices: true,
      layout: "web",
    },
    graphic: {
      src: "/images/projects/california-dmv/background/tile.png",
      parallax: "small",
    },
    gradientBG: true,
  },
  color: {
    primary: "#1F68A1",
    customGradientOverride: "180deg, #137EC7 0%, #73B8E8 59.5%, #B5E0FF 100%",
  },
  display: {
    arrowOverride: {
      src: "/images/projects/california-dmv/california-seal.png",
      alt: "The Great Seal of the State of California",
      width: 80,
      height: 80,
    },
    overlay: {
      color: "#16325A",
      opacity: 0.4,
    },
  },
};

const clear: companyType = {
  name: "Clear",
  project: {
    types: ["ios"],
    description: {
      intro: "Biometric Identity Platform",
      copy: [
        "After conquering airports and stadiums, Clear’s next move is into the broader world of digital identity. We worked with their leadership team to prepare a new offering in advance of their S1 filing to go public. From initial strategy through to execution, we rolled out a new SDK that allows any business to leverage Clear’s technology for authenticating users.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/clear/screens/iphone-x/account.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/clear/screens/iphone-x/home.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
    graphic: {
      src: "/images/projects/clear/gradient.png",
    },
  },
  color: {
    primary: "#1b295d",
  },
};

const crunchbase: companyType = {
  name: "Crunchbase",
  links: {
    appStore: "https://apps.apple.com/us/app/crunchbase/id1134089028",
    website: "https://crunchbase.com",
  },
  project: {
    types: ["ios", "design"],
    description: {
      intro: "Leading Company Insights Dashboard",
      copy: [
        "Newly spun out from AOL & Verizon, Crunchbase engaged Fueled to level-up their offering by launching a native mobile experience that brought the power of their vast website to a handy mobile experience. We worked directly with senior leadership to develop a strategy for mobile that would enhance the value proposition of their premium offering.",
      ],
    },
    gradientBG: true,
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/crunchbase/screens/iphone-x/feed.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/crunchbase/screens/iphone-x/company.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: {
    primary: "#0487d1",
    gradient: "#4ec3fd",
  },
};

const coinbase: companyType = {
  name: "Coinbase",
  links: {
    website: "https://coinbase.com",
  },
};

const compass: companyType = {
  name: "Compass",
  project: {
    types: ["ios"],
    graphic: {
      src: "/images/projects/compass/dots.png",
    },
    description: {
      intro: "Modern Real Estate Platform",
      copy: [
        "In the years ahead of going public at a $10 billion valuation, Compass engaged Fueled to level-up and grow a number of their consumer-facing apps and agent tools. We became their defacto partner when they needed reliable rollout of new features on any platform while also helping them build their in-house native mobile team.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/compass/screens/iphone-x/listing.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/compass/screens/iphone-x/login.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
};

// D
const dayo: companyType = {
  name: "Dayo",
  project: {
    types: ["ios", "brand"],
    deviceSet: {
      screens: [
        {
          deviceModel: "iphone-5",
          orientation: "screen",
          src: "/images/projects/dayo/screens/iphone-5/day-night-screen-1.png",
        },
        {
          deviceModel: "iphone-5",
          orientation: "screen",
          src: "/images/projects/dayo/screens/iphone-5/day-night-screen-3.png",
        },
      ],
      overlapDevices: true,
    },
  },
  color: { primary: "#E54348" },
};

const DCICapital = {
  name: "DCI Capital",
  color: {
    primary: "#43A0D9",
  },
};

// E
const example: companyType = {
  name: "Example",
  links: {
    appStore: "https://apple.com/link/to/the/app/store",
    playStore: "https://google.com/link/to/the/play/store",
    website: "https://example.com",
  },
};

const emrap: companyType = {
  name: "EM:RAP",
};

// F
const fennel: companyType = {
  name: "Fennel",
  project: {
    types: ["ios", "android", "brand", "design"],
  },
  color: {
    primary: "#280146",
  },
};

const figs: companyType = {
  name: "Figs",
  links: {
    website: "https://wearfigs.com",
  },
};

// G
const getaway: companyType = {
  name: "Getaway",
  links: {
    website: "https://getaway.house",
  },
};

const gimlet: companyType = {
  name: "Gimlet",
  links: {
    website: "https://gimletmedia.com",
  },
};

// H
const hagerty: companyType = {
  name: "Hagerty",
  project: { types: ["ios"] },
};

// I
const ibm: companyType = {
  name: "IBM",
  project: { types: ["ios"] },
};

const ikon: companyType = {
  name: "Ikon Pass",
  project: {
    types: ["ios", "android", "design"],
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/ikon/screens/iphone-12/map.jpg",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/ikon/screens/iphone-12/pass.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
    graphic: {
      src: "/images/projects/ikon/topo.svg",
    },
  },
  links: {
    appStore: "https://apps.apple.com/us/app/ikon-pass/id1482191120",
    playStore: "https://play.google.com/store/apps/details?id=com.alterramtnco.ikonpass",
    website: "https://www.ikonpass.com/",
  },
  color: { primary: "#092340" },
};

// J
const jackpocket: companyType = {
  name: "Jackpocket",
  links: {
    website: "https://jackpocket.com",
  },
};

// K
const keetoo: companyType = {
  name: "Keetoo",
  project: { types: ["ios"] },
};

const kiss = {
  name: "Kiss",
  color: {
    primary: "#911F1F",
  },
};

// L
const lagoFrame: companyType = {
  name: "Lago Frame",
  links: {
    website: "https://lagoframe.com/",
  },

  project: {
    types: ["ios", "android", "blockchain"],
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/lago-frame/screens/iphone-12/home.png",
          orientation: "screen",
          deviceModel: "iphone-12",
        },
        {
          src: "/images/projects/lago-frame/screens/iphone-12/home2.png",
          orientation: "screen",
          deviceModel: "iphone-12",
        },
      ],
    },
  },
  color: {
    primary: "#161616",
  },
};

// M
const makespace: companyType = {
  name: "MakeSpace",
  links: {
    website: "https://makespace.com",
  },
};

const meowwolf: companyType = {
  name: "MEOW WOLF",
  links: {
    appStore: "https://apps.apple.com/us/app/meow-wolf/id6446853169",
    playStore: "https://play.google.com/store/apps/details?id=com.meowwolf.androidapp",
    website: "https://meowwolf.com",
  },
  project: {
    types: ["ios", "android"],
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/meowwolf/screens/iphone-x/screen-1.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/meowwolf/screens/iphone-x/screen-2.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
    graphic: {
      src: "/images/projects/meowwolf/gradient.jpg",
    },
  },
  color: { primary: "#4E1BAC" },
};

const mgm: companyType = {
  name: "MGM",
  project: {
    types: ["digital-transformation", "ios", "android"],

    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/mgm/screens/iphone-12/events-scrolled.png",
          orientation: "screen",
          deviceModel: "iphone-12",
        },
        {
          src: "/images/projects/mgm/screens/iphone-x/reservation.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: { primary: "#a9803b" },
};

const moneylion: companyType = {
  name: "MoneyLion",
  links: {
    website: "https://www.moneylion.com/",
  },
};

const moscase: companyType = {
  name: "Moscase",
  links: {
    website: "https://moscase.com",
  },
};

const myplace: companyType = {
  name: "MyPlace",
};

const mysteryvibe: companyType = {
  name: "Mysteryvibe",
  links: {
    appStore: "https://itunes.apple.com/us/app/mysteryvibe/id1060838797",
    playStore: "https://play.google.com/store/apps/details?id=com.mysteryvibe.mysteryvibe",
  },
  project: {
    types: ["ios", "brand"],
    deviceSet: {
      overlapDevices: true,
      layout: "default",
      screens: [
        {
          src: "/images/projects/mysteryvibe/screens/iphone-12/foreplay.png",
          orientation: "screen",
          deviceModel: "iphone-12",
        },
        {
          src: "/images/projects/mysteryvibe/screens/iphone-12/playtime.png",
          orientation: "screen",
          deviceModel: "iphone-12",
        },
      ],
    },
  },
  color: { primary: "#39123F" },
};

// N
const numovi = {
  name: "Numovi",
  color: {
    primary: "#177820",
  },
};

// O
const omstars: companyType = {
  name: "Omstars",
  links: {
    website: "https://omstars.com",
  },
  project: { types: ["brand", "web"] },
};

// P
const pedal: companyType = {
  name: "Pedal",
  links: {},
};

const pollen: companyType = {
  name: "Pollen",
  project: {
    types: ["ios", "brand"],
    deviceSet: {
      screens: [
        {
          deviceModel: "iphone-5",
          orientation: "screen",
          src: "/images/projects/pollen/screens/iphone-5/polish-phone-poster.jpg",
        },
        {
          deviceModel: "iphone-5",
          orientation: "screen",
          src: "/images/projects/pollen/screens/iphone-5/photography-phone-2.jpg",
        },
      ],
      overlapDevices: true,
    },
  },
  color: { primary: "#F0D011" },
};

const publicHoldings: companyType = {
  name: "Public",
  links: {
    website: "https://public.com",
  },
  project: {
    types: ["ios"],
    description: {
      intro: "Social-Focused stock trading",
      copy: [
        "Fueled wrote Public’s first line of code, handling their full stack, including the ability to process stock transactions. We went on to invest in them and help launch a business that their Series D valued at $1.2 billion. We supported the project post-launch for about a year as we helped them ramp up their team.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/public/screens/iphone-x/login.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/public/screens/iphone-x/stock.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: { primary: "#FFFFFF" },
};

// Q
const quizup: companyType = {
  name: "QuizUp",
  links: {
    playStore: "https://play.google.com/store/apps/details?id=com.quizup.core&hl=en",
  },
  project: {
    types: ["android", "ios"],
    description: {
      intro: "Quiz Game with 100 Million Downloads",
      copy: [
        "As they attracted millions of users and rocketed up the App Store charts, they were missing just one thing: An Android version. We delivered in record time.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          deviceModel: "iphone-x",
          orientation: "screen",
          src: "/images/projects/quizup/screens/iphone-x/profile.png",
        },
        {
          deviceModel: "iphone-x",
          orientation: "screen",
          src: "/images/projects/quizup/screens/iphone-x/quiz.png",
        },
      ],
    },
  },
  color: {
    primary: "#FF5454",
  },
};

// R
const richr: companyType = {
  name: "Richr",
  links: {
    website: "https://www.richr.com/",
  },
  project: {
    types: ["brand", "ios"],
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/richr/screens/iphone-x/listing.png",
          orientation: "front",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/richr/screens/iphone-12/guide.png",
          orientation: "front",
          deviceModel: "iphone-12",
        },
      ],
    },
  },
  color: {
    primary: "#0082BC",
  },
};

const riteaid: companyType = {
  name: "Rite Aid",
  links: {
    appStore: "https://itunes.apple.com/us/app/rite-aid/id508852374?mt=8",
    playStore: "https://play.google.com/store/apps/details?id=com.riteaid.android&hl=en_GB",
  },
  project: {
    types: ["ios", "android", "apple-watch"],
    description: {
      intro: "The pharmacy to find both traditional & alternative remedies",
      copy: [
        "Through simplifying the prescription refill and pickup process, our team reinvented the Rite Aid mobile pharmacy experience for both iOS and Android. And through innovative fingerprint recognition and a reworked backend API, we ensured Rite Aid’s customers’ information was kept safe and secure. At launch, Rite Aid saw an impressive 7% increase in prescription refills.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/riteaid/screens/iphone-x/store.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/riteaid/screens/iphone-x/home.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: {
    primary: "#0D96D4",
  },
};

// S

const sakara: companyType = {
  name: "Sakara",
  links: {
    website: "https://www.sakara.com/",
  },
  project: {
    types: ["ios"],
    description: {
      intro: "Wellness Focused Meal Delivery",
      copy: [
        "We worked with Sakara to build their first native app, simplifying the day to day meal management of their clients while also making it easier than ever to purchase Sakara's wellness focused products on the go. To achieve this, we identified pain points on their existing website and created an app design that streamline the most common workflows.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/sakara/screens/iphone-x/meal.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/sakara/screens/iphone-x/shop.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
    graphic: {
      src: "/images/projects/sakara/powder.png",
      parallax: "small",
    },
  },
  color: { primary: "#FFFFFF" },
};

const shinesty: companyType = {
  name: "Shinesty",
  links: {
    website: "https://shinesty.com",
  },
};

const smash: companyType = {
  name: "Smash",
  links: {
    appStore: "https://itunes.apple.com/app/id1108416207",
  },
};

const streamful: companyType = {
  name: "Streamful",
  links: {
    website: "https://streamful.com",
  },
  project: { types: ["web", "brand"] },
};

const superhuman: companyType = {
  name: "Superhuman",
  links: {
    website: "https://superhuman.com",
  },
};

// T
const thrillist: companyType = {
  name: "Thrillist",
  links: {
    website: "https://www.thrillist.com/",
  },
};
const trafficCarma = {
  name: "TrafficCarma",
  color: {
    primary: "#3779E1",
  },
};

const tonyRobbins: companyType = {
  name: "Tony Robbins",
  project: {
    types: ["ios"],
    description: {
      intro: "Renowned business and life coach",
      copy: [
        "Tony Robbins engaged Fueled to reimagine his organization’s consumer-facing offerings. From rebuilding a digital learning platform to working with management to build and rollout new products for his coaching business, we’ve helped shepherd Tony’s organization through a digital transformation.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/tony-robbins/screens/iphone-x/tracks.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/tony-robbins/screens/iphone-x/power.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
    graphic: {
      src: "/images/projects/tony-robbins/airpods.png",
      parallax: "large",
      onTop: true,
    },
    gradientBG: true,
  },
  color: {
    primary: "#0b121b",
    gradient: "#14aecf",
  },
};

const trace: companyType = {
  name: "Trace",
  project: {
    types: ["ios", "blockchain", "design"],
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/trace/screens/iphone-x/grow.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/trace/screens/iphone-x/preview.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: {
    primary: "#877696",
  },
};

// U
const uniqlo: companyType = {
  name: "Uniqlo",
  project: { types: ["ios"] },
};
const unitedNations: companyType = {
  name: "World Food Programme",
  project: {
    types: ["ios"],
    description: {
      intro: "World Food Programme",
      copy: [
        "The WFP runs one of the most complex distribution networks on the planet, from remote deserts to active warzones. They engaged Fueled to build a series of internal command and control tools for field offices around the world. Perhaps it’s just a coincidence, but they did win a Nobel Peace Prize after we launched our product with them.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/united-nations/screens/iphone-x/global.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/united-nations/screens/iphone-x/breakdown.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: { primary: "#7dc3d9" },
};

// V

const verizon: companyType = {
  name: "Verizon",
  links: {
    appStore: "https://itunes.apple.com/us/app/my-verizon-mobile/id416023011?mt=8",
    playStore: "https://play.google.com/store/apps/details?id=com.vzw.hss.myverizon&hl=en",
  },
  project: {
    types: ["ios"],
    description: {
      intro: "Telecommunications Provider",
      copy: [
        "With their existing app showing its age, they engaged Fueled to upgrade and modernize their flagship mobile experience. We worked with them to design an innovative in-store experience that allows customers to interact with products and discover exclusive content as they pass through special Bluetooth Low Energy (BLE) zones within the store.",
      ],
    },
    deviceSet: {
      overlapDevices: true,
      screens: [
        {
          src: "/images/projects/verizon/screens/iphone-x/watch.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
        {
          src: "/images/projects/verizon/screens/iphone-x/home.png",
          orientation: "screen",
          deviceModel: "iphone-x",
        },
      ],
    },
  },
  color: { primary: "#ed1c23" },
};

// W

const wandr: companyType = {
  name: "Wandr",
};

const wsj: companyType = {
  name: "Wall Street Journal",
  project: {
    types: ["web", "design"],
    deviceSet: {
      screens: [
        {
          src: "/images/projects/wsj/screens/macbook-air/tech-screen-hero.jpg",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
        {
          src: "/images/projects/wsj/screens/macbook-air/foef-screen-hero.jpg",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
      ],
      overlapDevices: true,
      layout: "web",
    },
  },
  color: { primary: "#1d1d1d" },
};

const warbyParker: companyType = {
  name: "Warby Parker",
  links: {
    appStore: "https://itunes.apple.com/app/id1107693363?mt=8",
  },
  project: {
    types: ["ios"],
    image: {
      src: "/images/projects/warby-parker/screen-glasses.png",
    },
  },
  color: { primary: "#00a2e1" },
};

const whiteHouse: companyType = {
  name: "White House",
  project: {
    types: ["web", "design"],
    description: {
      intro: "Official Website Of The White House",
      copy: [
        "With an intense, fixed Inauguration Day deadline and little time to onboard users, Fueled's CMS team helped revamp the WhiteHouse.gov site on a foundation of WordPress combined with a block-based live editing experience that was intuitive enough that a wide range of White House staff were able to add content to the site with zero training.",
      ],
    },
    deviceSet: {
      screens: [
        {
          src: "/images/projects/white-house/screens/macbook-air/white-house-screen-inner-one.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
        {
          src: "/images/projects/white-house/screens/macbook-air/white-house-screen-inner-two.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
      ],
      overlapDevices: true,
      layout: "web",
    },
  },
  color: { primary: "#0A2359" },
  display: {
    logoMaxWidthPercent: 65,
  },
};

const wirecutter: companyType = {
  name: "New\xa0York\xa0Times / Wirecutter",
  shortName: "NYT / Wirecutter",
  links: {
    website: "https://beta.thewirecutter.com",
  },
  project: {
    types: ["web"],
    deviceSet: {
      screens: [
        {
          src: "/images/projects/wirecutter/screens/macbook-air/backyard-party.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
        {
          src: "/images/projects/wirecutter/screens/macbook-air/travel-list.png",
          orientation: "screen",
          deviceModel: "macbook-air",
        },
      ],
      overlapDevices: true,
      layout: "web",
    },
  },
  color: { primary: "#6085ff", accent: "#3358d5" },
};

// X

const xponential: companyType = {
  name: "Xponential",
};

// Y

const youhue: companyType = {
  name: "YouHue",
  links: {
    website: "https://www.youhue.com/",
  },
};

// Z

const zift: companyType = {
  name: "Zift",
  links: {
    appStore: "https://itunes.apple.com/us/app/zift-digital-family-control/id1211763414?ls=1&mt=8",
    playStore: "https://play.google.com/store/apps/details?id=com.contentwatch.ghoti.cp2.parent",
    website: "https://wezift.com/",
  },
  project: { types: ["ios", "web"] },
};

// Export
export const COMPANIES: Record<string, companyType> = {
  "9gag": NINEgag,
  aap,
  afterlight,
  apple,
  apartner,
  artsy,
  artwired,
  "barneys-new-york": barneysNewYork,
  "blue-bottle-coffee": blueBottleCoffee,
  buildspec,
  emrap,
  "california-dmv": californiaDmv,
  clear,
  coinbase,
  compass,
  crunchbase,
  dayo,
  "dci-capital": DCICapital,
  example,
  fennel,
  figs,
  getaway,
  gimlet,
  hagerty,
  ibm,
  ikon,
  jackpocket,
  keetoo,
  kiss,
  "lago-frame": lagoFrame,
  makespace,
  meowwolf,
  mgm,
  moneylion,
  moscase,
  myplace,
  mysteryvibe,
  numovi,
  omstars,
  pedal,
  pollen,
  public: publicHoldings,
  quizup,
  richr,
  riteaid,
  sakara,
  shinesty,
  smash,
  streamful,
  superhuman,
  thrillist,
  "tony-robbins": tonyRobbins,
  trace,
  "traffic-carma": trafficCarma,
  uniqlo,
  "united-nations": unitedNations,
  verizon,
  wandr,
  "warby-parker": warbyParker,
  "white-house": whiteHouse,
  wirecutter,
  wsj,
  youhue,
  xponential,
  zift,
};
